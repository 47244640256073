import React from 'react';
import type { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import type { HeaderComponentQuery } from 'graphql-types';

export const Header: FC = () => {
  const data = useStaticQuery<HeaderComponentQuery>(graphql`
    query HeaderComponent {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <header>
      <h1>
        <Link to="/">{data.site?.siteMetadata?.title ?? '(無題)'}</Link>
      </h1>
      <p>{data.site?.siteMetadata?.description ?? ''}</p>
    </header>
  );
};

import React from 'react';
import type { FC } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import type { FooterComponentQuery } from 'graphql-types';

export const Footer: FC = () => {
  const data = useStaticQuery<FooterComponentQuery>(graphql`
    query FooterComponent {
      site {
        siteMetadata {
          author
          email
        }
      }
    }
  `);
  return (
    <footer>
      <p>
        &copy; {data.site?.siteMetadata?.author ?? '(Unknown)'} All right
        reserved.
      </p>
      <ul>
        <li>
          <Link to="/about">
            {data.site?.siteMetadata?.author ?? '(Unknown)'}について
          </Link>
        </li>
      </ul>
    </footer>
  );
};

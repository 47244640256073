import React from 'react';
// eslint-disable-next-line import/no-named-as-default
import Helmet from 'react-helmet';
import type { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import type { HeadComponentQuery } from 'graphql-types';

interface HeadProps {
  articleTitle?: string;
}
export const Head: FC<HeadProps> = ({ articleTitle }) => {
  console.log('articleTitle in Head:' + articleTitle);
  const { site } = useStaticQuery<HeadComponentQuery>(graphql`
    query HeadComponent {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  const siteTitle = site?.siteMetadata?.title
    ? site?.siteMetadata?.title
    : 'NO NAME';
  const headTitle = articleTitle ? articleTitle + ' | ' + siteTitle : siteTitle;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja'
      }}
      title={headTitle}
      meta={[
        {
          name: 'description',
          content: `${site?.siteMetadata?.description ?? ''}`
        }
      ]}
    />
  );
};

import React from 'react';
import type { FC } from 'react';
import { Header } from './header';
import { Footer } from './footer';
import '../styles/index.scss';
import '@fontsource/m-plus-rounded-1c';
import { Head } from './head';

interface LayoutProp {
  children: React.ReactNode;
  articleTitle?: string;
}

export const Layout: FC<LayoutProp> = ({ children, articleTitle }) => {
  console.log('articleTitle:' + articleTitle);
  return (
    <>
      <div className="wrapper">
        <Head articleTitle={articleTitle} />
        <Header />
        <div className="content">{children}</div>
        <Footer />
      </div>
    </>
  );
};
